if (document.readyState !== 'loading') {
	initNavigation();
} else {
	document.addEventListener('DOMContentLoaded', initNavigation);
}

function initNavigation() {
	Array.from(document.querySelectorAll('[data-target]')).forEach(function (el) {
		el.addEventListener('click', function(ev) {
			ev.preventDefault()
			document.getElementById(el.getAttribute('data-target')).classList.toggle('is-active')
		})
	})

	Array.from(document.querySelectorAll('[data-faqopen]')).forEach(function (el) {
		el.addEventListener('click', function(ev) {
			ev.preventDefault()
			el.parentNode.classList.toggle('mActive')
		})
	})

	Array.from(document.querySelectorAll('[data-modal]')).forEach(function (el) {
		el.addEventListener('click', function(ev) {
			ev.preventDefault()
			document.getElementById(el.getAttribute('data-modal')).classList.toggle('is-active')
		})
	})

	Array.from(document.querySelectorAll('.modal-background, .modal-close')).forEach(function (el) {
		el.addEventListener('click', function(ev) {
			ev.preventDefault()
			el.parentNode.classList.remove('is-active')
		})
	})
}