// Third party
const $ = require('jquery');
const CookieConsent = require('./cookie-consent.js');

// Array from polyfil
if (typeof Array.from == 'undefined') {
	Array.from = function (object) {
		return Array.slice.call(object)
	}
}

// Site scripts
const navigation = require('./navigation.js');
const cookieSetup = require('./cookie-setup.js');
const animations = require('./animations.js');