const ScrollReveal = require('./scrollreveal.js');

if (document.readyState !== 'loading') {
	initAnimations();
} else {
	document.addEventListener('DOMContentLoaded', initAnimations);
}

function initAnimations() {
	ScrollReveal().reveal('.slide-up, .slide-up-0', {
		origin: 'bottom',
		distance: '200px',
		duration: 1500,
		delay: 200
	});
	ScrollReveal().reveal('.slide-up-1', {
		origin: 'bottom',
		distance: '200px',
		duration: 1500,
		delay: 400
	});
	ScrollReveal().reveal('.slide-up-2', {
		origin: 'bottom',
		distance: '200px',
		duration: 1500,
		delay: 600
	});
	ScrollReveal().reveal('.slide-up-3', {
		origin: 'bottom',
		distance: '200px',
		duration: 1500,
		delay: 800
	});
	ScrollReveal().reveal('.slide-up-4', {
		origin: 'bottom',
		distance: '200px',
		duration: 1500,
		delay: 1000
	});
	ScrollReveal().reveal('.slide-down', {
		origin: 'top',
		distance: '200px',
		duration: 1500,
		delay: 200
	});
	ScrollReveal().reveal('.slide-left, .slide-left-0', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 200
	});
	ScrollReveal().reveal('.slide-left-1', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 400
	});
	ScrollReveal().reveal('.slide-left-2', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 600
	});
	ScrollReveal().reveal('.slide-left-3', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 800
	});
	ScrollReveal().reveal('.slide-left-4', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 1000
	});
	ScrollReveal().reveal('.slide-left-5', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 1200
	});
	ScrollReveal().reveal('.slide-left-6', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 1400
	});
	ScrollReveal().reveal('.slide-left-7', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 1600
	});
	ScrollReveal().reveal('.slide-left-8', {
		origin: 'right',
		distance: '200px',
		duration: 1500,
		delay: 1800
	});
	ScrollReveal().reveal('.slide-right, .slide-right-0', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 200
	});
	ScrollReveal().reveal('.slide-right-1', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 400
	});
	ScrollReveal().reveal('.slide-right-2', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 600
	});
	ScrollReveal().reveal('.slide-right-3', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 800
	});
	ScrollReveal().reveal('.slide-right-4', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 1000
	});
	ScrollReveal().reveal('.slide-right-5', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 1200
	});
	ScrollReveal().reveal('.slide-right-6', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 1400
	});
	ScrollReveal().reveal('.slide-right-7', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 1600
	});
	ScrollReveal().reveal('.slide-right-8', {
		origin: 'left',
		distance: '200px',
		duration: 1500,
		delay: 1800
	});
}
