// https://github.com/klaxit/cookie-consent

window.analyticsInjected = false

if (navigator.doNotTrack != 1) {
	var cc = new window.CookieConsent({
		title: 'We use cookies 🍪',
	})
	cc.on('change', checkAnalytics)

	checkAnalytics()
	if (cc.status !== 'accepted' && cc.status !== 'rejected') {
		cc.open()
	}

	function checkAnalytics() {
		if (window.CookieConsent.acceptedCategories.includes('analytics') && window.analyticsInjected == false) {
			window.analyticsInjected = true
console.log('send analytics')
			// Google Analytics - Analytics.js
/*			el = document.createElement('script')
			el.text = "(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){"+
			"(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),"+
			"m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)"+
			"})(window,document,'script','//www.google-analytics.com/analytics.js','ga');"+
			"ga('create', 'UA-50056403-1', 'ardsshoppingcentre.com');"+
			"ga('set', 'anonymizeIp', true);"+
			"ga('require', 'displayfeatures');"+
			"ga('send', 'pageview');"
			document.head.appendChild(el)*/


			// Google Analytics - Gtag
			var el = document.createElement('script')
			el.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-6HVE1VCNQT')
			document.head.appendChild(el)

			el = document.createElement('script')
			el.text = "window.dataLayer = window.dataLayer || [];"+
			"function gtag(){dataLayer.push(arguments);}"+
			"gtag('js', new Date());"+
			"gtag('config', 'G-6HVE1VCNQT');"
			document.head.appendChild(el)

			// Facebook
/*			el = document.createElement('script')
			el.text = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?"+
			"n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;"+
			"n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;"+
			"t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,"+
			"document,'script','https://connect.facebook.net/en_US/fbevents.js');"+
			"fbq('init', '1443436192337262');"+
			"fbq('track', 'PageView');"
			document.head.appendChild(el)*/

			// pinterest
/*			el = document.createElement('script')
			el.text = "!function(e){if(!window.pintrk){window.pintrk=function(){window.pintrk.queue.push(Array.prototype.slice.call(arguments))};"+
			"var n=window.pintrk;n.queue=[],n.version='3.0';"+
			"var t=document.createElement('script');t.async=!0,t.src=e;"+
			"var r=document.getElementsByTagName('script')[0];r.parentNode.insertBefore(t,r)}}('https://s.pinimg.com/ct/core.js');pintrk('load','2613511064580',{em:'<user_email_address>'});pintrk('page');pintrk('track','pagevisit');"
			document.head.appendChild(el)*/

			// Hotjar
/*			el = document.createElement('script')
			el.text = "(function(h,o,t,j,a,r){"+
			"h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};"+
			"h._hjSettings={hjid:2419076,hjsv:6};"+
			"a=o.getElementsByTagName('head')[0];"+
			"r=o.createElement('script');r.async=1;"+
			"r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;"+
			"a.appendChild(r);"+
			"})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
			document.head.appendChild(el)*/
		}
	}
}
